.font-face-roboto,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
button,
.Toastify__toast {
  font-family: "Roboto", sans-serif !important;
}

/* Headings */
h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
  font-weight: 700 !important;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.875rem;
}

h6 {
  font-size: 0.75rem;
}

/* Anchor tags (links) */
a {
  font-size: 1.1rem;
}

:root {
  --Overview-background: #f2f2f2 !important;
  --primary-background-color: white !important;
  --primary-text-color: black !important;
  --button-background-color: #6fac6a !important;
  --logo-color: #0d4d00 !important;
  --checkbox-color: #0d4d00 !important;
  --input-field-color: #f2f2f2 !important;
  --button-text-color: #000 !important;
  --button-hover-color: #90c48b !important;
  --location-link-color: #90c48b !important;
  --calendar-hover-color: #90c48b;
  --stepper-color: #fff;
  --stepper-active-color: #d1e9c6;
}

.red {
  --Overview-background: #fdd0c6 !important;
  --primary-background-color: #ff9a84 !important;
  --primary-text-color: #333 !important;
  --button-background-color: #ff5733 !important;
  --logo-color: #aa0000 !important;
  --checkbox-color: #aa0000 !important;
  --input-field-color: #ffc8bc !important;
  --button-text-color: #222 !important;
  --button-hover-color: #ff7f5e !important;
  --location-link-color: #aa0000 !important;
  --calendar-hover-color: #ff7f5e;
  --stepper-color: #ffece7;
  --stepper-active-color: #ff5733;
}

.primary {
  --Overview-background: #b4e1ff !important;
  --primary-background-color: #045f9d !important;
  --primary-text-color: #fff !important;
  --button-background-color: #3498db !important;
  --logo-color: #002f4e !important;
  --checkbox-color: #002f4e !important;
  --input-field-color: #002f4e !important;
  --button-text-color: #fff !important;
  --button-hover-color: #58a9ed !important;
  --location-link-color: #179df6 !important;
  --calendar-hover-color: #58a9ed;
  --stepper-color: #d4ecfc;
  --stepper-active-color: #004776;
}

.dark {
  --Overview-background: #000 !important;
  --primary-background-color: #222 !important;
  --primary-text-color: #fff !important;
  --button-background-color: #444 !important;
  --logo-color: #fff !important;
  --checkbox-color: #000 !important;
  --input-field-color: #000 !important;
  --button-text-color: #fff !important;
  --button-hover-color: #000 !important;
  --location-link-color: #179df6 !important;
  --calendar-hover-color: #444;
  --stepper-color: #636363;
  --stepper-active-color: #222;
}

/* -------------------Category page----------------------------------------------------------- */
.Category-page-container {
  background-color: var(--primary-background-color);
}

.Category-page-container .location-text {
  color: var(--primary-text-color);
}

.select-button {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
}

.select-button:hover {
  background-color: var(--button-hover-color);
}
/* -------------------Service page------------------------------------------------------ */
.location-page-container .location-text {
  color: var(--primary-text-color);
}

.location-page-container {
  background-color: var(--primary-background-color);
}

/* ------------------------Main page------------------------------------------------- */

.Booking_overview_container {
  background-color: var(--Overview-background);
}
.Booking_overview_container .step {
  background-color: var(--stepper-color);
}
.Booking_overview_container .step.active {
  background-color: var(--stepper-active-color);
  color: var(--button-text-color);
}
.Booking_overview_container .step.completed {
  background-color: var(--stepper-active-color);
  color: var(--button-text-color);
}

.Booking_overview_container .handle_back_button {
  color: var(--button-text-color);
}

/* ..................Calendar work--------------------------------------------- */

.Calendar-page-container {
  background-color: var(--primary-background-color);
}

/* .Calendar-page-container .react-calendar__tile {
  background-color: var(--primary-background-color);
} */

.Calendar-page-container .react-calendar__navigation__arrow {
  background-color: var(--primary-background-color);
}
.Calendar-page-container .react-calendar__tile--active {
  background-color: var(--button-hover-color) !important;
}
.Calendar-page-container .react-calendar__tile:hover {
  background-color: var(--button-hover-color);
}
.Calendar-page-container .react-calendar__navigation__label {
  background-color: var(--primary-background-color);
  color: var(--button-text-color);
}
.Calendar-page-container .react-calendar__month-view abbr {
  color: var(--button-text-color);
}
.Calendar-right-panel-wrapper .timeSlot-text:hover {
  background-color: var(--calendar-hover-color);
  color: var(--button-text-color);
}

.Calendar-timing-wrapper .Calendar-sideBar-service-info {
  background-color: var(--primary-background-color);
}

.Calendar-sideBar-more-info {
  color: var(--button-text-color);
}
.location-text {
  color: var(--button-text-color);
}
.Calendar-right-panel-wrapper .timeSlot-text {
  background-color: var(--primary-background-color);
  color: var(--button-text-color);
}

.container-footer-info span {
  color: var(--button-text-color);
}

/* ------------------Details page------------------------------------------------------------------ */

.details-page-container {
  background-color: var(--primary-background-color);
}

.user_selected_info .location_color {
  color: var(--location-link-color) !important;
}

.checbox_input_field:checked {
  background-color: var(--checkbox-color);
}

.input-container input {
  background-color: var(--input-field-color);
  color: var(--button-text-color) !important;
}

.rc-anchor-light {
  background-color: var(--primary-background-color) !important;
}

/* --------------------------------Confirmation Accordian-------------------------------------------------------------- */
.header_logo_wrapper {
  width: 160px;
  overflow: hidden;
}
.header_logo_wrapper img {
  object-fit: cover;
  width: 160px;
}

.app_logo_text {
  color: var(--logo-color);
}

.css-1fderfv-MuiPaper-root-MuiAccordion-root {
  border: none !important;
  background-color: #fff !important;
}
.css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root {
  background-color: #fff !important;
  padding: 0px !important;
  margin: -6px !important;
}
.css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root
  .MuiAccordionSummary-content {
  margin-left: -5px !important;
}
.css-pwcg7p-MuiCollapse-root {
  border-left: 2px solid #aaaaaa !important;
}
.css-ta84vm-MuiAccordionDetails-root {
  margin-bottom: -35px !important;
}
.booking_date_time_selection_wrapper .user-confirmation-form {
  height: auto !important;
}
.css-78njj9 {
  background-color: #fff !important;
}
.css-1bdpvgx {
  border: none !important;
}
.css-c4sutr {
  border-left: 2px solid #b7b7b7 !important;
}
.css-1fpbxjw {
  border-top: none !important;
}
.css-78njj9 {
  margin: -7px !important;
  padding: 0px !important;
}

/* -----------------Media query------------------------------------------------------------------- */

@media (max-width: 600px) {
  .booking_date_time_selection_wrapper .user-confirmation-form {
    height: auto !important;
  }
}
@media (max-width: 400px) {
  .header_logo_wrapper,
  .header_logo_wrapper img {
    width: 120px;
  }
}

.d-flex {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.d-flex .nhsuk-radios {
  margin: 0;
}

.accordion-sec {
  margin-top: 20px;
}
