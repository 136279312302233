.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.nav-title {
  display: flex;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  color: #08649e;
  padding: 10px;
}
.pre-consultation-form {
  background-color: #f0f4f5;
  width: 100%;
  height: 1000px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login_form {
  padding: 30px;
  background-color: #fff;
  height: auto;
  left: 50%;
  top: 5%;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.login_form h3 {
  font-size: 16px;
  font-weight: bold;
}
.login_form h1 {
  color: red;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: flex;
  margin-bottom: 5px;
  margin-left: 5px;
  color: #565656;
}

input,
select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  margin-bottom: 10px;
  border-radius: 15px;
  border: 1px solid #444;
}

/* Styling for radio buttons */
input[type="radio"] {
  margin-right: 5px;
}

/* Additional styling for the form container */
.form-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.login_button {
  background-color: #444;
  border-radius: 30px;
  padding: 10px;
  color: #fff;
  cursor: pointer;
  margin-top: 50px;
}
.login_button:hover {
  background-color: #fff;
  color: #444;
  border: 1px solid #444;
}
.sign-in-link {
  margin-top: 30px;
}
.pre-consultation-form .error {
  margin-left: 10px;
  color: #e94141;
  display: flex;
}

.pre-consultation-form .css-1nrlq1o-MuiFormControl-root {
  display: flex !important;
}

.pre-consultation-form .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 20px !important;
  border: 1px solid #444 !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 10px !important;
}
.pre-consultation-form
  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #7e7e7e !important;
}
.pre-consultation-form .css-4jnixx-MuiStack-root > .MuiTextField-root {
  width: 100% !important;
}

.service_title_wrapper {
  width: 100%;
  height: 50px;
  border-radius: 15px;
  border: 1px solid #444;
  display: flex;
  justify-content: center;
  align-items: center;
}
.service_title_wrapper p {
  font-size: 20px;
  margin-top: 10px;
  color: #6e6e6e;
}
