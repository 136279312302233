.Calendar-page-container {
  margin-top: 20px;
  width: 100%;
  height: auto;
  /* border-radius: 5px; */
  /* background-color: white; */
  /* border: 1px solid rgb(232, 232, 232); */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.Calendar-page-container .react-calendar__tile--active {
  border-radius: 5px;
  /* background-color: #d3e0f1 !important; */
  color: #000;
}

.Calendar-page-container .react-calendar__tile {
  border: none;
  /* background-color: #fff; */
}

.Calendar-page-container .react-calendar__tile:hover {
  border: 1px solid gray;
  border-radius: 5px;
  /* background-color: #000; */
  cursor: pointer;
}

.Calendar-page-container .custom-calendar {
  margin: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
}

.Calendar-page-container .react-calendar__navigation {
  display: flex;
  justify-content: space-between;
  color: #222;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.Calendar-page-container .react-calendar__navigation__arrow {
  font-size: 40px;
  color: #90c48b;
  /* cursor: pointer; */
}

.Calendar-page-container .react-calendar__navigation__prev2-button {
  display: none !important;
}

.Calendar-page-container .react-calendar__navigation__next2-button {
  cursor: pointer;
  display: none;
}

.Calendar-page-container .react-calendar__navigation__label {
  border: none;
  /* background-color: #fff; */
  font-weight: bold;
  color: #5f5f5f;
  font-size: 16px;
}

.Calendar-page-container .react-calendar__navigation__arrow {
  border: none;
  /* background-color: #fff; */
}

.Calendar-page-container .react-calendar__month-view {
  text-align: center;
  margin-top: 20px;
  /* background-color: #fff !important; */
}

.Calendar-page-container .react-calendar__month-view abbr {
  text-decoration: none !important;
}

.Calendar-page-container .react-calendar__month-view__days {
  margin-top: 20px;
  background-color: #fff !important;
  height: 250px;
}

.Calendar-timing-wrapper {
  height: auto;
  display: flex;
  justify-content: space-between;
}

.Calendar-timing-wrapper .Calendar-sideBar-wrapper {
  background-color: transparent;
  height: auto;
  width: 35%;
}
.Calendar-timing-wrapper .Calendar-right-panel-wrapper {
  margin-left: 20px;
  margin-top: 20px;
  width: 100%;
  height: auto;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 20px;
  box-sizing: border-box;
}

.Calendar-right-panel-wrapper .timeSlot-text {
  /* width: 110px; */
  /* height: 35px; */
  border-radius: 5px;
  text-align: center;
  padding: 8px;
  margin: 5px;
  /* background-color: #d3e0f1; */
  /* color: #494949; */
  cursor: pointer;
  font-weight: 500;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.Calendar-right-panel-wrapper .timeSlot-text:hover {
  /* background-color: #90c48b !important; */
}

.Calendar-sideBar-service-info {
  margin-top: 20px;
  padding: 15px;
  height: auto;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.Calendar-sideBar-service-info span {
  flex-wrap: wrap;
  font-size: 16px;
  line-height: 1.22;
  font-weight: 500;
}

.Calendar-sideBar-service-info i {
  margin-left: 10px;
}

.Calendar-sideBar-service-info .hourGlassicon {
  margin-top: 15px;
  margin-left: 10px;
}

.handle-info {
  cursor: pointer;
}

.Calendar-sideBar-more-info {
  margin-top: 25px;
}

.Calendar-sideBar-more-info .more-details {
  font-weight: 400;
  font-family: "Rubik";
}

.separator-line {
  height: 1px;
  background-color: #bebebe;
  width: 100%;
  margin-bottom: 20px;
}

.container-caption {
  margin-left: 40px;
  font-size: 15px;
  font-weight: 600;
}

.calendar-time-slot-wrapper{
  width: 100%;
}
.container-footer-info {
  display: flex;
  position: absolute;
  bottom: 1;
  right: 0;
  margin-right: 40px;
}

.container-footer-info div{
  margin-top: 3px;
  margin-right: 3px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #d3e0f1;
}